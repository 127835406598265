import { TranslationsType } from './types';

export const TRANSLATIONS: TranslationsType = {
  actions: 'Actions',
  address: 'Adresse',
  adminSettings: "Paramètres d'administration",
  allergenEnum: {
    celery: 'Céleri',
    crustaceans: 'Crustacés',
    eggs: 'Œufs',
    fish: 'Poisson',
    gluten: 'Gluten',
    lupin: 'Lupin',
    milk: 'Lait',
    molluscs: 'Mollusques',
    mustard: 'Moutarde',
    nuts: 'Noix',
    peanuts: 'Cacahuètes',
    sesame: 'Sésame',
    soya: 'Soja',
    sulphurDioxide: 'Dioxyde de soufre',
  },
  allergens: 'Allergènes',
  amountOfBoxes: 'Quantité de boîtes',
  'api-keys': 'Clés API',
  apiKeysPage: {
    apiKeyCreated: 'La clé Api a été créée',
    apiKeyDeleted: 'La clé Api a été supprimée',
    copyToken: 'Copier le jeton',
    create: 'Clé API créée',
    tokenCopied: 'Jeton copié',
    tokenHasBeenCopied: "Le jeton a été copié et peut être collé dans l'application tierce",
  },
  areYouSure: 'Etes vous sûr?',
  authentication: 'Authentification',
  authenticationType: "Type d'authentification",
  automaticallySendNotification: 'Envoyer des notifications automatiquement',
  available: 'Disponible',
  box: 'Compartiment',
  boxes: 'Compartiments',
  brandColor: 'Couleur de la marque',
  brandingSettings: 'Paramètres de la marque',
  cancel: 'Annuler',
  canUseMealVouchers: 'Peut utiliser des chèques-repas?',
  changePasswordPage: {
    confirmPassword: 'Confirmer le mot de passe',
    disclaimer:
      "Pour une sécurité améliorée, nous vous recommandons fortement d'utiliser la connexion par e-mail ou SSO au lieu de configurer un mot de passe.",
    enablePasswordLogin: 'Activer la connexion par mot de passe',
    password: 'Mot de passe',
    passwordsNotEqual: 'Password and confirm password should be the same',
    passwordTooShort: 'Le mot de passe est trop court',
    title: 'Modifier le mot de passe',
  },
  city: 'Ville',
  close: 'Fermer',
  connectionStatus: 'Statut de connexion',
  contactDetails: 'Coordonnées',
  content: 'Contenu',
  countries: {
    be: 'Belgique',
    fr: 'France',
    nl: 'Pays-Bas',
  },
  country: 'Pays',
  createdAt: 'Créé à',
  currentStatus: 'Statut actuel',
  customer: 'Client',
  customers: 'Clients',
  customersPage: {
    couldNotDelete: 'Impossible de supprimé le client',
    create: 'Créer un client',
    customerCreated: 'Le client a été créé avec succès',
    customerRemoved: 'Le client a été retiré',
    customerUpdated: 'Mise à jour du client réussie',
    invalidPincode: 'Le code PIN doit comporter 5 chiffres',
    noContactInformation: 'Pas de coordonnées',
    noContactInformationDescription:
      "vous n'avez pas fourni de coordonnées pour le client {firstName}, nous ne pourrons donc pas envoyer de notifications pour cette raison.",
    notFound: 'Client introuvable',
    searchCustomer: 'Rechercher un client...',
  },
  date: {
    lastMonth: 'Le mois dernier',
    lastWeek: 'La semaine dernière',
    nextMonth: 'Le mois prochain',
    nextWeek: 'La semaine prochaine',
    today: "Aujourd'hui",
    tomorrow: 'Demain',
    yesterday: 'Hier',
  },
  delete: 'Supprimer',
  description: 'Description',
  details: 'Détails',
  deviceId: "Numéro d'identification de l'appareil",
  deviceLogs: {
    title: "Journaux de l'Appareil",
  },
  discount: 'Remise',
  discountPercentage: 'Pourcentage de remise',
  documentation: 'Documentation',
  edit: 'Modifier',
  email: 'Adresse e-mail',
  emailNotification: 'Notification par e-mail',
  errors: {
    serverError: "Une erreur provenant du serveur s'est produite",
  },
  export: 'Exporter',
  externalId: 'ID externe',
  extraInformation: 'Informations supplémentaires',
  firmwareVersion: 'Version du microprogramme',
  firstName: 'Prénom',
  hasNutritionalInformation: "Contient l'information des valeurs nutritionnelles",
  id: 'Identifiant',
  image: 'Image',
  import: 'Importer',
  includedProducts: 'Produits inclus',
  interval: 'Intervalle',
  isBetaTester: 'Recevoir le microprogramme bêta ?',
  isInActive: 'Est inactif',
  isVendingReservation: 'Est-ce pour Woyti?',
  isVirtualVendingEnabled: 'Activer Woyti',
  language: 'Langue',
  languageEnum: {
    de: 'Allemand',
    en: 'Anglais',
    fr: 'Français',
    nl: 'Néerlandais',
  },
  lastErrorAt: 'Dernière erreur',
  lastErrorMessage: "Dernier message d'erreur",
  lastName: 'Nom de famille',
  lastSeenAt: 'Dernière consultation',
  lastStateUpdate: 'Dernière mise à jour du statut',
  linkedReservations: 'Réservations liées',
  locker: 'Casier',
  lockers: 'Casiers',
  lockersPage: {
    boxOpened: 'La boîte a été ouverte',
    connectALocker: 'Connecter un casier',
    couldNotDelete: 'Impossible de supprimer le casier',
    couldNotOpenBox: "La boîte n'a pas pu être ouverte",
    created: 'Le casier a été créé',
    disableLockdown: 'Désactiver le blocage',
    disableLockdownConfirm:
      'Êtes-vous sûr de vouloir désactiver le verrouillage pour ce locker? Cela est généralement dû à une erreur technique de température, veuillez vous assurer que tout aliment stocké à la mauvaise température est éliminé.',
    enableLockdown: 'Désactiver le verrouillage',
    enableLockdownConfirm:
      'Êtes-vous sûr de vouloir activer le verrouillage pour ce verrou ? Les clients ne seront pas en mesure de récupérer leurs commandes tant que cette option est activée.',
    lockdownMessage:
      'Le box était bloquée à cause de problèmes de température. Recherchez la cause et vérifiez la qualité des produits contenus dans le box. Cliquez sur ‘désactiver le blocage’ au niveau des casiers pour autoriser à nouveau les collectes.',
    hasBeenModified: 'le casier a été modifié',
    inTheVersbox: 'Dans la Versbox',
    lockdownStatusUpdated: 'Le statut de verrouillage a été mis à jour',
    notFound: 'Casier introuvable',
    openAll: 'Ouvrez toutes les compartiments',
    openMainDoor: 'Ouvrir la porte principale',
    settings: 'Paramètres du casier',
    viewAllReservations: 'Voir toutes les réservations',
    viewReservation: 'Voir la réservation',
  },
  lockerTemperatureType: {
    NotCooled: 'Non réfrigéré',
    Refrigerated: 'Réfrigéré',
    RoomTemperature: 'Température de la pièce',
    Heated: 'Chauffé',
  },
  lockStatusses: {
    closed: 'Fermé',
    open: 'Ouvert',
    opened: 'Ouvert',
  },
  log: 'Registre',
  logo: 'Logo',
  LogOperationEnum: {
    Created: 'Créé',
    Deleted: 'Supprimé',
    Error: 'Erreur',
    Opened: 'Ouvert',
    Received: 'Reçu',
    Sent: 'Envoyé',
    Updated: 'Modifié',
  },
  logs: 'Registres',
  logsPage: {
    notFound: 'Registre non trouvé',
    operationFilter: 'Trier par opération',
    severityFilter: 'Trier par sévérité',
    typeFilter: 'Trier par type',
  },
  mainDoor: "Porte d'entrée",
  message: 'Message',
  minimumAmount: 'Montant minimum',
  name: 'Nom',
  newNotifications: 'Vous avez de nouvelles notifications',
  next: 'Suivant',
  noAutoComplete: 'Réservation rectifiée (ne pas remplir automatiquement)',
  noBoxReserved: 'Aucun casier réservé, le code postal du client ne peut servir que si un casier a été réservé',
  notification: 'Notification',
  notifications: 'Notifications',
  number: 'Numéro',
  nutritionalInformation: 'Valeurs nutritionnelles',
  nutritionalInformationItems: {
    addedSugars: 'Sucres ajoutés',
    carbohydrate: 'Glucides',
    cholesterol: 'Cholestérol',
    energy: 'Valeur énergétique (en kcal)',
    fat: 'Matières grasses',
    fiber: 'Fibres',
    protein: 'Protéines',
    salt: 'Sel',
    saturatedFat: 'dont graisses saturées',
    sugars: 'Sucres',
  },
  operation: 'Opération',
  order: 'Commande',
  orderAmount: 'Montant total de la commande',
  joynPoints: 'Joyn points',
  orderConfirmationEmailTemplate: 'E-mail de confirmation de commande',
  orderLines: 'Articles commandés',
  orderNumber: 'Numéro de commande',
  orders: 'Commandes',
  ordersPage: {
    confirmationSent: 'Confirmation de commande envoyée',
    create: 'Créer la commande',
    notFound: 'Commande non trouvée',
    orderCanceled: 'La commande a été annulée',
    orderChanged: 'Commande modifiée avec succès',
    orderStatus: 'Statut de la commande',
    searchOrder: 'Trouver une commande...',
  },
  refund: {
    title: 'Refund',
    minimumRefundError: 'Should refund at least 1 cent',
    maximumRefundError: 'Cannot refund more than the order total',
    amount: 'Refund amount',
    description: 'Refund description',
    descriptionPlaceholder: 'Return of goods #125',
    confirm: 'Confirm refund',
    success: 'Refund successfully sent',
    failed: 'Could not refund order',
  },
  orderStatusEnum: {
    Canceled: 'Annulée',
    PaymentCanceled: 'Payment Canceled',
    Created: 'Créée',
    Expired: 'Expirée',
    PaymentExpired: 'Payment Expired',
    Paid: 'Payée',
    Refunded: 'Remboursée',
  },
  organisation: 'Organisation',
  organisationScreen: {
    companyName: "Nom de l'entreprise",
    couldNotRemove: "Impossible de supprimer l'organisation",
    create: 'Créer une organisation',
    created: "L'organisation a été créée",
    registrationNumber: "Numéro de l'entreprise",
    registrationNumberRequired: "Le numéro de l'entreprise est requis",
    search: 'Rechercher une organisation',
    selectionHeading: 'Organisations',
  },
  origin: 'Origine',
  overviewScreen: {
    inLocker: 'Casier',
    inQueue: "File d'attente",
    someDevicesAreOffline:
      'Les dispositifs « {devices} » sont hors ligne, ce qui peut avoir pour conséquence que certaines réserves ne soient pas synchronisées avec le dispositif.',
    title: "Vue d'ensemble",
    vendingTag: 'Woyti',
    hiddenTag: 'Caché',
    lockersInLockdown:
      'Les casiers "{lockers}" sont en confinement, cela est probablement dû à un problème de température, allez à la page des casiers pour plus de détails.',
    woytiOrdersToNotify: 'Il reste à notifier les clients de {amount} nouvelles réservations Woyti',
  },
  overwriteCodeScreen: {
    changedSuccess: 'Code de remplacement modifié avec succès',
    confirmRemoval: 'Êtes-vous sûr de vouloir supprimer ce code de remplacement ?',
    create: 'Créer le code de remplacement',
    created: 'Le code de remplacement a été créé',
    editTitle: 'Modifier le code de remplacement',
    invalidPincode: 'Le code PIN doit comporter 6 chiffres',
    notFound: 'Code de remplacement non trouvé',
    overviewTitle: 'Codes de remplacement',
    removedSuccess: 'Le code de remplacement {name} a été supprimé',
    search: 'Trouver un code de remplacement...',
  },
  pageHeader: {
    organisationSelection: "Sélection de l'organisation",
    shopSelection: 'Sélection du magasin',
  },
  paidAt: 'Payé à',
  password: 'Mot de passe',
  passwordValidation: {
    length: 'Minimum 8 caractères',
    lowercase: 'Contient des minuscules',
    numbers: 'Contient des chiffres',
    uppercase: 'Contient des majuscules',
  },
  phonenumber: 'Numéro de téléphone',
  pickupCode: 'Code de retrait',
  pickupDate: 'Date de retrait',
  pickupTime: 'Heure de retrait',
  pincode: 'Code PIN',
  previous: 'Précédent',
  price: 'Prix',
  pricePerType: 'Prix par {type}',
  product: 'Produit',
  products: 'Produits',
  productsPage: {
    changed: 'Le produit a été modifié avec succès',
    changeFailed: "Le produit n'a pas pu être modifié",
    create: 'Créer un produit',
    created: 'Le produit a été créé',
    creationFailed: "Le produit n'a pas pu être créé",
    deleteFailed: "Le produit n'a pas pu être supprimé",
    notFound: 'Produit introuvable',
    removed: 'Le produit a été supprimé avec succès',
    search: 'Trouver un produit...',
    deleteAll: 'Delete all',
    productsDeleted: 'Deleted products',
  },
  provideOwnPincode: 'Ne pas générer automatiquement un code PIN ',
  quotaDetails: {
    title: 'Détails du quota',
    usageLog: "Historique d'usage",
  },
  quotas: {
    lastResetAt: 'Dernière remise à',
    resetsAt: 'Réinitialisation à',
    title: 'Quotas',
    type: 'Type de quota',
    usage: "Quota d'utilisation",
  },
  rawData: 'Données brutes',
  refresh: 'Actualisation',
  refunded: 'Remboursé',
  relatedDocuments: 'Documents liés',
  removeAt: 'Supprimé automatiquement de',
  reservation: 'Réservation',
  reservationActions: {
    boxesRequired: 'Tous les compartiments doivent être sélectionnés',
    cancellationWarning:
      "Voulez-vous vraiment annuler la réservation ? Cette action est irréversible. N'oubliez pas de vider le compartiment.",
    completeReservation: 'Terminer la réservation',
    completeReservationConfirmation: 'Voulez-vous vraiment terminer la réservation ?',
    insufficientBoxes: 'Pas assez de compartiments disponibles',
    orderAmountShouldBeLargerThanZero: 'Le montant de la commande doit être supérieur à zéro',
    reserveBox: 'Réserver un compartiment',
    reserveBoxesForReservation: 'Sélectionner le ou les compartiment(s) à réserver',
    selectABox: 'Sélectionner un compartiment',
    sendNotification: 'Envoyer la notification',
    sendNotificationConfirmation: 'Voulez-vous vraiment envoyer une notification ?',
    shouldSelectALocker: 'Devrait choisir un compartiment',
    showRecentlyOpenedBoxes: 'Afficher les compartiments récemment ouvertes ?',
    toCreated: 'Annuler la réservation de la consigne',
    toCreatedConfirmation: 'Voulez-vous vraiment annuler la réservation de la consigne ?',
  },
  reservationReadyEmailTemplate: "Modèle d'e-mail de notification envoyé lorsque la commande est prête",
  reservationReadySMSTemplate: 'Modèle de SMS de notification envoyé lorsque la commande est prête',
  reservations: 'Réservations',
  reservationScreen: {
    confirmUpdateSentCompleted:
      'Voulez-vous vraiment modifier cette réservation? Une notification a déjà été envoyée, ne changez donc pas le client ou le code PIN pour éviter les problèmes. Cela ne doit être utilisé que pour corriger les erreurs dans les coordonnées.',
    create: 'Créer une réservation',
    createWoyti: 'Créer une réservation woyti',
    createReservation: 'Créer une réservation',
    editReservation: 'Modifier la réservation',
    formTabs: {
      contactDetails: 'Coordonnées',
      extraOptions: 'Options supplémentaires',
      general: 'Généralités',
      vending: 'Woyti',
    },
    hasAlreadyPaid: 'A déjà payé',
    invalidPincode: 'Le code pin doit comporter 4 chiffres',
    noNotificationTypesSelected:
      "Comme aucune notification n'a été sélectionnée, le client ne recevra pas de notification avec le code PIN pour récupérer la commande.",
    noNotificationTypesSelectedTitle: 'Aucun type de notification sélectionné',
    notFound: 'Aucune réservation trouvée',
    notFoundListDescription: 'Aucune réservation trouvée, veuillez modifier les filtres',
    notificationDispatchFailed: "Impossible d'envoyer la notification",
    notificationSent: 'Notification envoyée',
    reservationCreated: 'La réservation a été enregistrée',
    reservationHasChanged: 'La réservation a changé, cliquez sur rafraîchir pour afficher les valeurs mises à jour.',
    reservationInformation: 'Détails de la réservation',
    reservationModified: 'La réservation a bien été modifiée',
    reservationStatusFilter: 'Statut de la réservation',
    search: 'Rechercher une réservation…',
    sendNotificationAt: 'Envoyer la notification à',
    shouldCustomerPay: 'Le client doit-il encore payer ?',
    shouldPay: 'Doit payer',
    statusChanged: 'Le statut a bien été modifié',
    statusChangeFailed: 'Impossible de modifier le statut',
    viewLocker: 'Afficher la consigne',
    woytiNotificationDispatched: 'Notification Woyti envoyée',
    woytiNotificationDispatchingFailed: "Échec de l'envoi de la notification Woyti",
    dispatchWoytiNotification: 'Envoyer la notification Woyti',
  },
  reservationSettingsScreen: {
    title: 'Reservation Settings',
    shouldCustomerPay: 'Should Customer Pay',
    selectAvailableBoxByDefault: 'Select available box by default',
    changed: 'Reservation settings successfully updated',
  },
  reservationStatusEnum: {
    cancelled: 'Annulé',
    completed: 'Achevé',
    created: 'Créé',
    ordered: 'Commandé',
    reserved: 'Réservé',
    sent: 'Notification envoyée',
  },
  role: 'Rôle',
  save: 'Enregistrer',
  select: 'Sélectionner',
  selectAnOrganisation: 'Sélectionner une organisation',
  sendFeedback: 'Commentaires',
  serverNotifications: {
    noNotifications: 'No notifications right now',
  },
  'service-location': 'Gateway',
  'service-locations': 'Gateways',
  serviceLocationScreen: {
    lastOfflineAt: 'Last Offline',
  },
  serviceLocationsPage: {
    changed: 'Gateway changed',
    changeFailed: 'Could not change gateway',
    create: 'Create gateway',
    created: 'Gateway successfully created',
    creationFailed: 'Gateway could not be created',
    notFound: 'Gateway not found',
    search: 'Find gateway...',
  },
  settings: {
    changed: 'Les paramètres ont été modifiés',
    generalSettings: 'Paramètres généraux',
    email: {
      settings: 'Paramètres email',
      validation: {
        domainRequiresSMTP: 'Vous devez indiquer un serveur SMTP pour tous les domaines autres que %s.',
      },
      customSMTPWarning:
        'Veuillez noter que nous ne pouvons pas garantir la livraison des courriels lorsque vous utilisez un serveur SMTP personnalisé, même si la connexion initiale est validée. Veuillez vous assurer que le serveur SMTP fourni est configuré pour envoyer des courriels en utilisant les paramètres fournis.',
      useCustomSettings: 'Utilisez vos propres paramètres de messagerie',
      fromEmail: "Adresse d'origine ('From: ')",
      smtpServer: 'Serveur SMTP',
      smtpPort: 'Port SMTP',
    },
    payment: 'Paramètres de paiement',
    paymentScreen: {
      connectMollie: "Se connecter à l'aide de Mollie",
      connectMollieAgain: 'Se reconnecter',
      disableTestmode: 'Désactiver le mode test',
      disableTestmodeConfirm: 'Êtes-vous sûr de vouloir désactiver le mode test ?',
      disconnectMollie: 'Disconnect Mollie',
      disconnectMollieConfirm:
        "Are you sure you want to disconnect Mollie, after doing this you won't be able to accept any more payments?",
      enableTestmode: 'Activer le mode test',
      enableTestmodeConfirm:
        'Êtes-vous sûr de vouloir activer le mode test ? Cela permettra aux clients de commander sans payer.',
      gotoMollieDashboard: 'Accéder au tableau de bord Mollie',
      mollieLinked: 'Mollie a bien été lié à votre compte',
      mollieOrganization: 'Mollie Organization',
      mollieOrganizationName: 'Organisation Mollie liée',
      mollieProfile: 'Mollie Profile',
      stillProcessing:
        'Traitement des informations en cours... Veuillez consulter le tableau de bord Mollie pour plus de détails',
      verificationStatus: 'Statut de la vérification',
      verified: 'Vérifié, peut recevoir des paiements',
    },
    virtualVending: 'Woyti settings',
    virtualVendingScreen: {
      findVirtualVendingAt: 'You can find your woyti machine at',
      favoriteCount: 'Woyti nombre de favoris: {count}',
    },
    joyn: {
      title: 'Joyn Settings',
      shopReference: 'Joyn Shop Reference',
    }
  },
  settingsPage: {
    changed: 'Les paramètres ont été modifiés',
    updateFailed: 'Impossible de modifier les paramètres',
  },
  severityEnum: {
    danger: 'Danger',
    info: 'Info',
    success: 'Réussi',
    warning: 'Attention',
  },
  shop: 'Boutique',
  shopScreen: {
    create: 'Créer une boutique',
    createdSuccessfully: 'La boutique a été créée',
    heading: 'Boutiques',
    hideVirtualVendingInOverview: 'Hide Woyti in overview',
    invalidPickupTime: "L'heure de ramassage n'est pas valide",
    invalidTimeBeforeDispatch: "Le délai avant la confirmation n'est pas valide",
    organisationSettings: "Paramètres de l'organisation",
    overview: "Vue d'ensemble des boutiques",
    search: 'Rechercher des boutiques...',
    timeBeforeConfirmationDispatch: 'Délai avant la notification de confirmation',
  },
  sidebar: {
    hide: 'Masquer la barre latérale',
  },
  smsNotification: 'Notification par SMS',
  state: 'Province',
  status: 'Statut',
  street: 'Rue',
  subject: 'Sujet',
  temperature: 'Température',
  testDevice: "Tester l'appareil",
  time: 'Horaire',
  total: 'Total',
  type: 'Type',
  undo: 'Annuler',
  unitEnum: {
    liter: 'Litre',
    kg: 'Kilogramme',
    unit: 'Unité',
  },
  unitAmount: {
    liter: 'Centilitre',
    kg: 'Gramme',
    unit: 'Unité',
  },
  disclaimer: {
    usingAgreeToTerms: 'By using this application you agree to our terms and conditions',
  },
  unitType: 'Unité',
  unknown: 'Inconnu',
  user: 'Utilisateur',
  username: "Nom d'utilisateur",
  userDropdown: {
    changePassword: 'Modifier le mot de passe',
    logout: 'Déconnexion',
    preferences: "Préférences de l'utilisateur",
  },
  userPreferences: {
    title: "Préférences de l'utilisateur",
    updatedSuccess: "Les préférences d'utilisateurs ont été modifiées avec succès",
  },
  usersPage: {
    deleteConfirmation: 'Êtes-vous sûr de vouloir supprimer cet utilisateur ?',
    invite: 'Inviter un nouvel utilisateur',
    inviteSendingFailed: "Impossible d'envoyer l'invitation",
    inviteSent: 'Envoyer une invitation',
    pincodeUpdated: 'Le code PIN a été modifié avec succès',
    pincodeUpdateFailed: 'Impossible de mettre à jour le code PIN',
    sendInvitation: 'Invitation envoyée',
    usersTitle: 'Utilisateurs',
  },
  validation: {
    addressRequired: 'Address is required',
    amountOfBoxes: 'La quantité de boîtes doit être supérieure ou égale à 1',
    boxCount: 'La quantité de boîtes est obligatoire',
    contentRequired: 'Contenu est obligatoire',
    descriptionRequired: 'Description est obligatoire',
    emailRequired: "L'adresse e-mail est obligatoire",
    firstNameRequired: 'Le prénom est obligatoire',
    intervalTooSmall: "L'intervalle doit être supérieur ou égal à 1.",
    invalidEmail: "L'adresse e-mail est invalide",
    invalidName: "Le nom n'est pas valide",
    invalidPhonenumber: "Le numéro de téléphone n'est pas valide",
    language: 'La langue est obligatoire',
    lastNameRequired: 'Le nom de famille est obligatoire',
    locker: 'Le compartiment est obligatoire',
    minimumAmountTooSmall: 'La quantité minimale doit être supérieure ou égale à 1',
    nameRequired: 'Le nom est obligatoire',
    phonenumber: 'Le numéro de téléphone est invalide',
    pickupInFuture: "L'heure d'enlèvement doit se situer dans le futur",
    pickupTimeRequired: "L'heure d'enlèvement est obligatoire",
    priceTooLow: 'Price should be at least 0',
    role: 'Le rôle est obligatoire',
    serviceLocation: 'Gateway is required',
    tinInvalid: 'Le numéro de TVA est invalide',
    tinRequired: 'Le numéro de TVA est obligatoire',
    type: 'Le type est obligatoire',
    vendingProducts: 'La réservation pour woyti doit contenir au moins un produit',
    fieldIsRequired: 'Field may not be empty',
  },
  vatNumber: 'Numéro TVA',
  vatRate: 'Tarif de la TVA',
  vending: 'Woyti',
  vendingPickupTime: "Délai entre la commande et l'enlèvement",
  vendingProducts: 'Products in Woyti reservation',
  viewAll: 'Tout afficher',
  virtualVendingMachine: 'Woyti',
  zipcode: 'Code postal',
  confirm: 'Confirm',
  webshopUrl: 'Webshop url',
  integrations: {
    title: 'Integrations',
    integration: 'Integration',
  },
  autoHideAfterXHours: 'Masquage automatique après x heures',
  hours: 'heures',
  temperatureLogs: {
    title: 'Journaux de température',
  },
  registeredAt: 'Inscrit à',
};

import { TranslationsType } from './types';

export const TRANSLATIONS: TranslationsType = {
  actions: 'Handlungen',
  address: 'Adresse',
  adminSettings: 'Admin-Einstellungen',
  allergenEnum: {
    celery: 'Sellerie',
    crustaceans: 'Schalentiere',
    eggs: 'Eier',
    fish: 'Fisch',
    gluten: 'Gluten',
    lupin: 'Lupine',
    milk: 'Milch',
    molluscs: 'Weichtiere',
    mustard: 'Senf',
    nuts: 'Nüsse',
    peanuts: 'Erdnüsse',
    sesame: 'Sesam',
    soya: 'Soja',
    sulphurDioxide: 'Schwefeldioxid',
  },
  allergens: 'Allergene',
  amountOfBoxes: 'Anzahl Schränke',
  'api-keys': 'API-Schlüssel',
  apiKeysPage: {
    apiKeyCreated: 'Ein API-Schlüssel wurde erstellt',
    apiKeyDeleted: 'Der API-Schlüssel wurde entfernt',
    copyToken: 'Token kopieren',
    create: 'API-Schlüssel erstellt',
    tokenCopied: 'Token kopiert',
    tokenHasBeenCopied: 'Der Token wurde kopiert und kann in eine Fremdapplikation eingefügt werden',
  },
  areYouSure: 'Bist du sicher?',
  authentication: 'Authentifizierung',
  authenticationType: 'Authentifizierungstyp',
  automaticallySendNotification: 'Benachrichtigungen automatisch senden',
  available: 'verfügbar',
  box: 'Abholfach',
  boxes: 'Schrank',
  brandColor: 'Markenfarbe',
  brandingSettings: 'Branding-Einstellungen',
  cancel: 'Abbrechen',
  canUseMealVouchers: 'Kann Essensgutscheine verwenden?',
  changePasswordPage: {
    confirmPassword: 'Passwort bestätigen',
    disclaimer:
      'Aus Sicherheitsgründen empfehlen wir dringend, die E-Mail-Anmeldung oder SSO zu verwenden, anstatt ein Kennwort zu konfigurieren.',
    enablePasswordLogin: 'Passwort-Login aktivieren',
    password: 'Passwort',
    passwordsNotEqual: 'Password and confirm password should be the same',
    passwordTooShort: 'Password is too short',
    title: 'Passwort ändern',
  },
  city: 'Stadt',
  close: 'Schließen',
  connectionStatus: 'Verbindungsstatus',
  contactDetails: 'Kontaktdetails',
  content: 'Inhalt',
  countries: {
    be: 'Belgien',
    fr: 'Frankreich',
    nl: 'Niederlande',
  },
  country: 'Land',
  createdAt: 'Erstellt auf',
  currentStatus: 'Aktueller Satus',
  customer: 'Kunde',
  customers: 'Kunden',
  customersPage: {
    couldNotDelete: 'Konnte Kunden nicht entfernen',
    create: 'Kunden erstellen',
    customerCreated: 'Kunde wurde erfolgreich erstellt',
    customerRemoved: 'Kunde wurde entfernt',
    customerUpdated: 'Aktualisierung Kunde erfolgreich',
    invalidPincode: 'Pincode sollte aus 5 Ziffern bestehen',
    noContactInformation: 'Keine Kontaktdaten',
    noContactInformationDescription:
      'Sie haben keine Kontaktdaten für den Kunden {firstName} zur Verfügung gestellt, daher werden wir Ihnen keine Benachrichtigungen senden können.',
    notFound: 'Kunde nicht gefunden',
    searchCustomer: 'Kunden suchen...',
  },
  date: {
    lastMonth: 'Vergangener Monat',
    lastWeek: 'Vergangene Woche',
    nextMonth: 'Kommender Monat',
    nextWeek: 'Kommende Woche',
    today: 'Heute',
    tomorrow: 'Morgen',
    yesterday: 'Gestern',
  },
  delete: 'Löschen',
  description: 'Beschreibung',
  details: 'Details',
  deviceId: 'Geräte-ID',
  deviceLogs: {
    title: 'Device Logs',
  },
  discount: 'Rabatt',
  discountPercentage: 'Rabattprozente',
  documentation: 'Dokumentation',
  edit: 'Bearbeiten',
  email: 'E-Mail',
  emailNotification: 'E-Mail-Benachrichtigung',
  errors: {
    serverError: 'Es ist ein Serverfehler aufgetreten',
  },
  export: 'Export',
  externalId: 'Externe ID',
  extraInformation: 'Zusätzliche Information',
  firmwareVersion: 'Firmware-Version',
  firstName: 'Vorname',
  hasNutritionalInformation: 'Enthält Nährwertinformation',
  id: 'ID',
  image: 'Bild',
  import: 'Import',
  includedProducts: 'Inbegriffene Produkte',
  interval: 'Intervall',
  isBetaTester: 'Erhält Beta-Firmware?',
  isInActive: 'Ist inaktiv',
  isVendingReservation: 'Is for Woyti?',
  isVirtualVendingEnabled: 'Enable Woyti',
  language: 'Sprache',
  languageEnum: {
    de: 'Deutsch',
    en: 'Englisch',
    fr: 'Französisch',
    nl: 'Niederländisch',
  },
  lastErrorAt: 'Letzter Fehler',
  lastErrorMessage: 'Letzte Fehlernachricht',
  lastName: 'Nachname',
  lastSeenAt: 'Zuletzt gesehen',
  lastStateUpdate: 'Letztes Status-Update',
  linkedReservations: 'Verbundene Reservierungen',
  locker: 'Abholfach',
  lockers: 'Abholfächer ',
  lockersPage: {
    boxOpened: 'Kiste wurde geöffnet',
    connectALocker: 'Verbinde ein Schließfach',
    couldNotDelete: 'Schließfach konnte nicht entfernt werden',
    couldNotOpenBox: 'Kiste konnte nicht geöffnet werden',
    created: 'Ein Schließfach wurde erstellt',
    disableLockdown: 'Blockierung deaktiveren',
    disableLockdownConfirm:
      'Sind Sie sicher, dass Sie die Verriegelung für dieses Schließfach deaktivieren möchten? Dies ist in der Regel auf einen technischen Fehler bei der Temperatur zurückzuführen. Bitte stellen Sie sicher, dass Lebensmittel, die bei falscher Temperatur gelagert wurden, entsorgt werden.',
    enableLockdown: 'Blockierung aktiveren',
    enableLockdownConfirm:
      'Sind Sie sicher, dass Sie die Sperre für dieses Schließfach aktivieren möchten? Solange diese Funktion aktiviert ist, können Kunden ihre Bestellungen nicht abholen.',
    lockdownMessage:
      'Die Box war aufgrund von Temperaturproblemen blockiert. Finden Sie die Ursache heraus uns überprüfen Sie die Qualität der Produkte in der Box. Klicken Sie bei Abholfächer auf ‘Blockierung deaktivieren’, um Abholungen wieder zu ermöglichen',
    hasBeenModified: 'Schließfach wurde geändert',
    inTheVersbox: 'In der Versbox',
    lockdownStatusUpdated: 'Lockdown status has been updated',
    notFound: 'Schließfach nicht gefunden',
    openAll: 'Alle abholfächer öffnen',
    openMainDoor: 'Haupttüre öffnen',
    settings: 'Schließfach-Einstellungen',
    viewAllReservations: 'Alle Reservierungen anschauen',
    viewReservation: 'Reservierung anschauen',
  },
  lockerTemperatureType: {
    NotCooled: 'Not Cooled',
    Refrigerated: 'Refrigerated',
    RoomTemperature: 'Room Temperature',
    Heated: 'Heated',
  },
  lockStatusses: {
    closed: 'Geschlossen',
    open: 'Öffnen',
    opened: 'Geöffnet',
  },
  log: 'Protokoll',
  logo: 'Logo',
  LogOperationEnum: {
    Created: 'Erstellt',
    Deleted: 'Gelöscht',
    Error: 'Fehler',
    Opened: 'Geöffnet',
    Received: 'Erhalten',
    Sent: 'Gesendet',
    Updated: 'Geändert',
  },
  logs: 'Protokolle',
  logsPage: {
    notFound: 'Protokoll nicht gefunden',
    operationFilter: 'Filter nach Betrieb',
    severityFilter: 'Filter nach Schweregrad',
    typeFilter: 'Filter nach Typ',
  },
  mainDoor: 'Haupttüre',
  message: 'Nachricht',
  minimumAmount: 'Mindestbetrag',
  name: 'Name',
  newNotifications: 'Du hast neue Nachrichten erhalten',
  next: 'Weiter',
  noAutoComplete: 'Feste Reservierung (nicht automatisch ausfüllen)',
  noBoxReserved:
    'Kein Abholfach reserviert! Der Pin-Code des Kunden kann nicht versendet werden, wenn kein Abholfach reserviert wurde.',
  notification: 'Benachrichtigung',
  notifications: 'Nachrichten',
  number: 'Hausnummer',
  nutritionalInformation: 'Nährwertinformationen',
  nutritionalInformationItems: {
    addedSugars: 'Zugesetzter Zucker',
    carbohydrate: 'Kohlenhydrate',
    cholesterol: 'Cholesterin',
    energy: 'Energiegehalt (in kcal)',
    fat: 'Fett',
    fiber: 'Ballaststoffe',
    protein: 'Proteine',
    salt: 'Salz',
    saturatedFat: 'Gesättigte Fette',
    sugars: 'Zucker',
  },
  operation: 'Operation',
  order: 'Bestellung',
  orderAmount: 'Gesamtbestellmenge',
  joynPoints: 'Joyn punkte',
  orderConfirmationEmailTemplate: 'E-Mail zur Auftragsbestätigung',
  orderLines: 'Bestellte Artikel',
  orderNumber: 'Bestellnummer',
  orders: 'Bestellungen',
  ordersPage: {
    confirmationSent: 'Auftragsbestätigung gesendet',
    create: 'Auftrag erstellen',
    notFound: 'Auftrag nicht gefunden',
    orderCanceled: 'Auftrag wurde storniert',
    orderChanged: 'Auftrag erfolgreich geändert',
    orderStatus: 'Bestellstatus',
    searchOrder: 'Auftrag finden...',
  },
  refund: {
    title: 'Refund',
    minimumRefundError: 'Should refund at least 1 cent',
    maximumRefundError: 'Cannot refund more than the order total',
    amount: 'Refund amount',
    description: 'Refund description',
    descriptionPlaceholder: 'Return of goods #125',
    confirm: 'Confirm refund',
    success: 'Refund successfully sent',
    failed: 'Could not refund order',
  },
  orderStatusEnum: {
    Canceled: 'Abgebrochen',
    PaymentCanceled: 'Payment Canceled',
    Created: 'Erstellt',
    Expired: 'Abgelaufen',
    PaymentExpired: 'Payment Expired',
    Paid: 'Bezahlt',
    Refunded: 'Rückerstattet',
  },
  organisation: 'Organisation',
  organisationScreen: {
    companyName: 'Name des Unternehmens',
    couldNotRemove: 'Die Organisation konnte nicht entfernt werden',
    create: 'Organisation erstellen',
    created: 'Organisation wurde erstellt',
    registrationNumber: 'Nummer des Unternehmens',
    registrationNumberRequired: 'Die Nummer des Unternehmens ist erforderlich',
    search: 'Organisation suchen',
    selectionHeading: 'Unternehmen',
  },
  origin: 'Ursprung',
  overviewScreen: {
    inLocker: 'Abholfach',
    inQueue: 'Warteschlange',
    someDevicesAreOffline:
      'Geräte "{devices}"  sind offline; das kann dazu führen, dass einige Buchungen nicht mit dem Gerät synchronisiert werden.',
    title: 'Überblick',
    vendingTag: 'Woyti',
    hiddenTag: 'Versteckt',
    lockersInLockdown: 'Woyti',
    woytiOrdersToNotify: 'Kunden noch über {amount} neue Woyti-Reservierungen benachrichtigen',
  },
  overwriteCodeScreen: {
    changedSuccess: 'Überschreib-Code erfolgreich geändert',
    confirmRemoval: 'Sind Sie sicher, dass Sie diesen Überschreib-Code entfernen möchten?',
    create: 'Überschreib-Code erstellen',
    created: 'Überschreib-Code wurde erstellt.',
    editTitle: 'Überschreib-Code bearbeiten',
    invalidPincode: 'Pincode sollte aus 6 Ziffern bestehen',
    notFound: 'Code zum Überschreiben nicht gefunden',
    overviewTitle: 'Überschreib-Codes',
    removedSuccess: 'Überschreib-Code {name} wurde entfernt.',
    search: 'Überschreib-Code finden …',
  },
  pageHeader: {
    organisationSelection: 'Unternehmensauswahl',
    shopSelection: 'Shop-Auswahl',
  },
  paidAt: 'Bezahlt auf',
  password: 'Passwort',
  passwordValidation: {
    length: 'Mindestens 8 Zeichen',
    lowercase: 'Enthält Kleinbuchstaben',
    numbers: 'Enthält Ziffern',
    uppercase: 'Enthält Großbuchstaben',
  },
  phonenumber: 'Telefonnummer',
  pickupCode: 'Abholcode',
  pickupDate: 'Abholtag',
  pickupTime: 'Abholzeit',
  pincode: 'Pincode',
  previous: 'Zurück',
  price: 'Preis',
  pricePerType: 'Preis pro {type}',
  product: 'Produkt',
  products: 'Produkte',
  productsPage: {
    changed: 'Das Produkt wurde erfolgreich geändert',
    changeFailed: 'Produkt konnte nicht geändert werden',
    create: 'Produkt erstellen',
    created: 'Produkt erstellt',
    creationFailed: 'Produkt konnte nicht erstellt werden',
    deleteFailed: 'Produkt konnte nicht entfernt werden',
    notFound: 'Produkt nicht gefunden',
    removed: 'Das Produkt wurde erfolgreich entfernt',
    search: 'Produkt finden...',
    deleteAll: 'Delete all',
    productsDeleted: 'Deleted products',
  },
  provideOwnPincode: 'Pincode nicht automatisch erstellen',
  quotaDetails: {
    title: 'Quota Details',
    usageLog: 'Usage Log',
  },
  quotas: {
    lastResetAt: 'Last Reset At',
    resetsAt: 'Resets at',
    title: 'Quotas',
    type: 'Quota Type',
    usage: 'Quota usage',
  },
  rawData: 'Rohdaten',
  refresh: 'Aktualisieren',
  refunded: 'Erstattet',
  relatedDocuments: 'Zugehörige Dokumente',
  removeAt: 'Automatisch entfernt von',
  reservation: 'Reservierung',
  reservationActions: {
    boxesRequired: 'Alle Kisten sollte eine Auswahl haben',
    cancellationWarning:
      'Sind Sie sicher, dass Sie die Reservierung stornieren wollen? Dieser Schritt kann nicht rückgängig gemacht werden, bitte vergessen Sie nicht, die Kiste zu leeren.',
    completeReservation: 'Reservierung fertigstellen',
    completeReservationConfirmation: 'Sind Sie sicher, dass Sie die Reservierung fertigstellen wollen?',
    insufficientBoxes: 'Nicht genügend verfügbare Kisten',
    orderAmountShouldBeLargerThanZero: 'Bestellmenge muss größer als null sein.',
    reserveBox: 'Abholfach reservieren',
    reserveBoxesForReservation: 'Kiste(n) für Reservierung auswählen',
    selectABox: 'Kisten auswählen',
    sendNotification: 'Benachrichtigung senden',
    sendNotificationConfirmation: 'Sind Sie sicher, dass Sie eine Benachrichtigung senden möchten?',
    shouldSelectALocker: 'Sollte ein Schließfach auswählen',
    showRecentlyOpenedBoxes: 'Kürzlich geöffnete Schränke anzeigen?',
    toCreated: 'Reservierung aus dem Abholfach entfernen',
    toCreatedConfirmation: 'Sind Sie sicher, dass Sie die Reservierung aus dem Schließfach entfernen wollen?',
  },
  reservationReadyEmailTemplate: 'E-Mail-Vorlage fertig zur Reservierung',
  reservationReadySMSTemplate: 'SMS-Vorlage fertig zur Reservierung',
  reservations: 'Reservierungen',
  reservationScreen: {
    confirmUpdateSentCompleted:
      'Sind Sie sicher, dass Sie eine Reservierung aktualisieren möchten, für die bereits eine Benachrichtigung verschickt wurde? Diese Funktion sollte nur zur Korrektur von Tippfehlern in den Kontaktdaten verwendet werden.',
    create: 'Reservierung erstellen',
    createWoyti: 'Create Woyti reservation',
    createReservation: 'Reservierung erstellen',
    editReservation: 'Reservierung bearbeiten',
    formTabs: {
      contactDetails: 'Kontaktdaten',
      extraOptions: 'Zusätzliche Optionen',
      general: 'Allgemein',
      vending: 'Woyti',
    },
    hasAlreadyPaid: 'Er hat bereits gezahlt.',
    invalidPincode: 'Pincode sollte aus 4 Ziffern bestehen',
    noNotificationTypesSelected:
      'Da Sie keinen Benachrichtigungstyp ausgewählt haben, wird Ihr Kunde keine Benachrichtigungen erhalten',
    noNotificationTypesSelectedTitle: 'Keine Benachrichtigungstypen ausgewählt',
    notFound: 'Keine Reservierungen gefunden',
    notFoundListDescription: 'Keine Reservierungen gefunden, bitte Filter aktualisieren',
    notificationDispatchFailed: 'Konnte keine Benachrichtigung senden',
    notificationSent: 'Benachrichtigung gesendet',
    reservationCreated: 'Eine Reservierung wurde erstellt',
    reservationHasChanged: 'Reservierung wurde geändert, „Aktualisieren“ drücken um Änderungen anzuzeigen',
    reservationInformation: 'Details der Reservierung',
    reservationModified: 'Die Reservierung wurde erfolgreich modifiziert',
    reservationStatusFilter: 'Reservierungsstatus',
    search: 'Reservierung finden...',
    sendNotificationAt: 'Benachrichtigung versenden am',
    shouldCustomerPay: 'Muss der Kunde noch zahlen?',
    shouldPay: 'Er muss noch zahlen.',
    statusChanged: 'Der Status wurde erfolgreich geändert',
    statusChangeFailed: 'Der Status konnte nicht geändert werden',
    viewLocker: 'Schließfach erstellen',
    woytiNotificationDispatched: 'Woyti-Benachrichtigung gesendet',
    woytiNotificationDispatchingFailed: 'Senden der Woyti-Benachrichtigung fehlgeschlagen',
    dispatchWoytiNotification: 'Woyti-Benachrichtigung senden',
  },
  reservationSettingsScreen: {
    title: 'Reservierungseinstellungen',
    shouldCustomerPay: 'Soll der Kunde bezahlen',
    selectAvailableBoxByDefault: 'Verfügbare Box standardmäßig auswählen',
    changed: 'Reservierungseinstellungen erfolgreich aktualisiert',
  },
  reservationStatusEnum: {
    cancelled: 'Storniert',
    completed: 'Abgeschlossen',
    created: 'Erstellt',
    ordered: 'Bestellt',
    reserved: 'Reserviert',
    sent: 'Benachrichtigung gesendet',
  },
  role: 'Rolle',
  save: 'Speichern',
  select: 'Auswählen',
  selectAnOrganisation: 'Wähle eine Organisation',
  sendFeedback: 'Feedback',
  serverNotifications: {
    noNotifications: 'Derzeit keine Benachrichtigungen',
  },
  'service-location': 'Gateway',
  'service-locations': 'Gateways',
  serviceLocationScreen: {
    lastOfflineAt: 'Zuletzt offline',
  },
  serviceLocationsPage: {
    changed: 'Gateway geändert',
    changeFailed: 'Gateway konnte nicht geändert werden',
    create: 'Gateway erstellen',
    created: 'Gateway erfolgreich erstellt',
    creationFailed: 'Gateway konnte nicht erstellt werden',
    notFound: 'Gateway nicht gefunden',
    search: 'Gateway suchen...',
  },
  settings: {
    changed: 'Die Einstellungen wurden gespeichert',
    generalSettings: 'Allgemeine Einstellungen',
    email: {
      settings: 'Email Einstellungen',
      validation: {
        domainRequiresSMTP: 'Sie müssen einen SMTP-Server für alle anderen Domänen als %s angeben.',
      },
      customSMTPWarning:
        'Bitte beachten Sie, dass wir bei der Verwendung eines benutzerdefinierten SMTP-Servers die Zustellung von E-Mails nicht garantieren können, selbst wenn die erste Verbindung validiert wurde. Bitte stellen Sie sicher, dass der bereitgestellte SMTP-Server so konfiguriert ist, dass er E-Mails mit den bereitgestellten Einstellungen versendet.',
      useCustomSettings: 'Verwenden Sie benutzerdefinierte E-Mail-Einstellungen',
      fromEmail: "Adresse des Versenders ('From: ')",
      smtpServer: 'SMTP-Server',
      smtpPort: 'SMTP-Port',
    },
    payment: 'Zahlungseinstellungen',
    paymentScreen: {
      connectMollie: 'Mit Mollie verbinden',
      connectMollieAgain: 'Nochmals verbinden',
      disableTestmode: 'Testmodus deaktivieren',
      disableTestmodeConfirm: 'Sind Sie sicher, dass Sie den Testmodus deaktivieren möchten?',
      disconnectMollie: 'Disconnect Mollie',
      disconnectMollieConfirm:
        "Are you sure you want to disconnect Mollie, after doing this you won't be able to accept any more payments?",
      enableTestmode: 'Testmodus aktivieren',
      enableTestmodeConfirm:
        'Sind Sie sicher, dass Sie den Testmodus aktivieren möchten? Kunden können dann bestellen, ohne zu bezahlen.',
      gotoMollieDashboard: 'Gehen Sie zum Mollie-Dashboard',
      mollieLinked: 'Erfolgreich mit Mollie verbunden',
      mollieOrganization: 'Mollie Organization',
      mollieOrganizationName: 'Verknüpftes Unternehmen Mollie',
      mollieProfile: 'Mollie Profile',
      stillProcessing: 'Information wird noch verarbeitet... Weitere Details entnehmen Sie bitte dem Mollie-Dashboard',
      verificationStatus: 'Verifizierungsstatus',
      verified: 'Verifiziert, kann Zahlungen empfangen',
    },
    virtualVending: 'Woyti settings',
    virtualVendingScreen: {
      findVirtualVendingAt: 'You can find your woyti machine at',
      favoriteCount: 'Woyti Favoritenanzahl: {count}',
    },
    joyn: {
      title: 'Joyn Einstellungen',
      shopReference: 'Joyn Shop Referenz',
    }
  },
  settingsPage: {
    changed: 'Die Einstellungen wurden gespeichert',
    updateFailed: 'Die Einstellungen konnten nicht geändert werden',
  },
  severityEnum: {
    danger: 'Gefahr',
    info: 'Info',
    success: 'Erfolg',
    warning: 'Warnhinweis',
  },
  shop: 'Shop',
  shopScreen: {
    create: 'Shop erstellen',
    createdSuccessfully: 'Shop wurde erstellt',
    heading: 'Geschäft',
    hideVirtualVendingInOverview: 'Hide Woyti in overview',
    invalidPickupTime: 'Abholzeit ist ungültig',
    invalidTimeBeforeDispatch: 'Zeit, bevor die Bestätigung ungültig wird',
    organisationSettings: 'Unternehmenseinstellungen',
    overview: 'Geschäft-Auswahl',
    search: 'Shops suchen...',
    timeBeforeConfirmationDispatch: 'Zeit vor Bestätigungsnachricht',
  },
  sidebar: {
    hide: 'Seitenleiste verbergen',
  },
  smsNotification: 'SMS-Benachrichtigung',
  state: 'Region',
  status: 'Status',
  street: 'Straße',
  subject: 'Gegenstand',
  temperature: 'Temperatur',
  testDevice: 'Testgerät',
  time: 'Zeit',
  total: 'Gesamt',
  type: 'Typ',
  undo: 'Rückgängig machen',
  unitEnum: {
    liter: 'Liter',
    kg: 'Kilogramm',
    unit: 'Einheit',
  },
  unitAmount: {
    liter: 'Centiliter',
    kg: 'Gramm',
    unit: 'Einheit',
  },
  disclaimer: {
    usingAgreeToTerms: 'By using this application you agree to our terms and conditions',
  },
  unitType: 'Einheit',
  unknown: 'Unbekannt',
  user: 'Nutzer',
  username: 'Nutzername',
  userDropdown: {
    changePassword: 'Passwort ändern',
    logout: 'Ausloggen',
    preferences: 'Benutzereinstellungen',
  },
  userPreferences: {
    title: 'Benutzereinstellungen',
    updatedSuccess: 'Benutzereinstellungen wurden erfolgreich aktualisiert',
  },
  usersPage: {
    deleteConfirmation: 'Sind Sie sicher, dass Sie diesen Nutzer entfernen wollen?',
    invite: 'Einen neuen Nutzer einladen',
    inviteSendingFailed: 'Konnte keine Einladung senden',
    inviteSent: 'Einladung senden',
    pincodeUpdated: 'Der PIN-Code wurde erfolgreich geändert',
    pincodeUpdateFailed: 'Der PIN-Code konnte nicht aktualisiert werden',
    sendInvitation: 'Einladung gesendet',
    usersTitle: 'Nutzer',
  },
  validation: {
    addressRequired: 'Address is required',
    amountOfBoxes: 'Die Anzahl der Kisten sollte 1 oder mehr betragen',
    boxCount: 'Anzahl der Kisten erforderlich',
    contentRequired: 'Inhalt erforderlich',
    descriptionRequired: 'Beschreibung erforderlich',
    emailRequired: 'E-Mail-Adresse erforderlich',
    firstNameRequired: 'Name der Firma erforderlich',
    intervalTooSmall: 'Das Intervall sollte 1 oder mehr betragen',
    invalidEmail: 'E-Mail-Adresse ungültig',
    invalidName: 'Name ungültig',
    invalidPhonenumber: 'Telefonnummer ungültig',
    language: 'Sprache erforderlich',
    lastNameRequired: 'Nachname erforderlich',
    locker: 'Schließfach erforderlich',
    minimumAmountTooSmall: 'Minimale Anzahl sollte 1 oder mehr betragen',
    nameRequired: 'Name erforderlich',
    phonenumber: 'Telefonnummer ungültig',
    pickupInFuture: 'Die Abholzeit sollte in der Zukunft liegen',
    pickupTimeRequired: 'Abholzeit erforderlich',
    priceTooLow: 'Price should be at least 0',
    role: 'Rolle erforderlich',
    serviceLocation: 'Gateway is required',
    tinInvalid: 'TIN-Nummer ungültig',
    tinRequired: 'TIN-Nummer erforderlich',
    type: 'Typ erforderlich',
    vendingProducts: 'Die Reservierung für woyti sollte mindestens ein Produkt enthalten',
    fieldIsRequired: 'Field may not be empty',
  },
  vatNumber: 'TIN-Nummer',
  vatRate: 'Steuersatz',
  vending: 'Woyti',
  vendingPickupTime: 'Zeit zwischen Auftragserteilung und Abholung',
  vendingProducts: 'Products in Woyti reservation',
  viewAll: 'Alle ansehen',
  virtualVendingMachine: 'Woyti',
  zipcode: 'Postleitzahl',
  confirm: 'Confirm',
  webshopUrl: 'Webshop url',
  integrations: {
    title: 'Integrations',
    integration: 'Integration',
  },
  autoHideAfterXHours: 'Automatisch ausblenden nach x stunden',
  hours: 'stunden',
  temperatureLogs: {
    title: 'Temperaturprotokolle',
  },
  registeredAt: 'Registriert bei',
};

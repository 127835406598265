import { TranslationsType } from './types';

export const TRANSLATIONS: TranslationsType = {
  actions: 'Acties',
  address: 'Adres',
  adminSettings: 'Admin Instellingen',
  allergenEnum: {
    celery: 'Selderij',
    crustaceans: 'Schaaldieren',
    eggs: 'Eieren',
    fish: 'Vis',
    gluten: 'Gluten',
    lupin: 'Lupine',
    milk: 'Melk',
    molluscs: 'Weekdieren',
    mustard: 'Mosterd',
    nuts: 'Noten',
    peanuts: 'Pindanoten',
    sesame: 'Sesamzaad',
    soya: 'Soja',
    sulphurDioxide: 'Zwaveldioxide',
  },
  allergens: 'Allergenen',
  amountOfBoxes: 'Aantal vakken',
  'api-keys': 'API Sleutels',
  apiKeysPage: {
    apiKeyCreated: 'Api sleutel aangemaakt.',
    apiKeyDeleted: 'Api sleutel verwijderd.',
    copyToken: 'Kopieer Token',
    create: 'Maak api sleutel aan',
    tokenCopied: 'Token gekopieerd',
    tokenHasBeenCopied: 'Token is gekopieerd en kan worden geplakt bij de applicatie dat u wilt linken',
  },
  areYouSure: 'Bent u zeker?',
  authentication: 'Authenticatie',
  authenticationType: 'Authenticatie type',
  automaticallySendNotification: 'Verzend notificatie automatisch',
  available: 'Beschikbaar',
  box: 'Vak',
  boxes: 'Vakken',
  brandColor: 'Merk kleur',
  brandingSettings: 'Branding Instellingen',
  cancel: 'Annuleer',
  canUseMealVouchers: 'Kan maaltijdcheques gebruiken?',
  changePasswordPage: {
    confirmPassword: 'Bevestig wachtwoord',
    disclaimer:
      'Voor een betere beveiliging raden we u ten zeerste aan om inloggen via e-mail of SSO te gebruiken in plaats van een wachtwoord te configureren.',
    enablePasswordLogin: 'Schakel wachtwoord login in',
    password: 'Wachtwoord',
    passwordsNotEqual: 'Wachtwoord en bevestig wachtwoord moeten hetzelfde zijn',
    passwordTooShort: 'Wachtwoord is te kort',
    title: 'Verander wachtwoord',
  },
  city: 'Stad',
  close: 'Sluit',
  connectionStatus: 'Connectie status',
  contactDetails: 'Contact gegevens',
  content: 'Inhoud',
  countries: {
    be: 'België',
    fr: 'Frankrijk',
    nl: 'Nederland',
  },
  country: 'Land',
  createdAt: 'Aangemaakt op',
  currentStatus: 'Huidige status',
  customer: 'Klant',
  customers: 'Klanten',
  customersPage: {
    couldNotDelete: 'Kon de klant niet verwijderen',
    create: 'Maak een klant aan',
    customerCreated: 'Klant is succesvol aangemaakt',
    customerRemoved: 'Klant is verwijderd',
    customerUpdated: 'Klant is succesvol aangepast',
    invalidPincode: 'De pincode moet uit 5 cijfers bestaan',
    noContactInformation: 'Geen contact gegevens',
    noContactInformationDescription:
      'U heeft geen contactgegevens opgegeven voor de klant {firstName}, hierdoor zal deze klant geen notificaties ontvangen.',
    notFound: 'Klant niet gevonden',
    searchCustomer: 'Zoek naar een klant...',
  },
  date: {
    lastMonth: 'Afgelopen maand',
    lastWeek: 'Afgelopen week',
    nextMonth: 'Komende maand',
    nextWeek: 'Komende week',
    today: 'Vandaag',
    tomorrow: 'Morgen',
    yesterday: 'Gisteren',
  },
  delete: 'Verwijder',
  description: 'Beschrijving',
  details: 'Details',
  deviceId: 'Apparaat ID',
  deviceLogs: {
    title: 'Apparaat logboeken',
  },
  discount: 'Korting',
  discountPercentage: 'Kortingspercentage',
  documentation: 'Documentatie',
  edit: 'Pas aan',
  email: 'E-mail',
  emailNotification: 'Email Notificatie',
  errors: {
    serverError: 'Er is een serverfout opgetreden',
  },
  export: 'Exporteer',
  externalId: 'Externe ID',
  extraInformation: 'Extra informatie',
  firmwareVersion: 'Firmware versie',
  firstName: 'Voornaam',
  hasNutritionalInformation: 'Bevat informatie over voedingswaarden',
  id: 'ID',
  image: 'Afbeelding',
  import: 'Importeer',
  includedProducts: 'Inbegrepen Producten',
  interval: 'Interval',
  isBetaTester: 'Ontvangt bètafirmware?',
  isInActive: 'Non Actief',
  isVendingReservation: 'Is voor Woyti?',
  isVirtualVendingEnabled: 'Woyti inschakelen',
  language: 'Taal',
  languageEnum: {
    de: 'Duits',
    en: 'Engels',
    fr: 'Frans',
    nl: 'Nederlands',
  },
  lastErrorAt: 'Meest recente error op',
  lastErrorMessage: 'Meest recente error bericht',
  lastName: 'Achternaam',
  lastSeenAt: 'Laatst gezien',
  lastStateUpdate: 'Laatste status update',
  linkedReservations: 'Gelinkte reservaties',
  locker: 'Locker',
  lockers: 'Lockers',
  lockersPage: {
    boxOpened: 'Box is geopend',
    connectALocker: 'Connecteer een locker',
    couldNotDelete: 'Kon de locker niet verwijderen',
    couldNotOpenBox: 'Box kon niet geopend worden',
    created: 'Locker is aangemaakt',
    disableLockdown: 'Schakel blokkering uit',
    disableLockdownConfirm:
      'Weet u zeker dat u de blokkering voor deze locker wilt uitschakelen? Dit is meestal te wijten aan een technische fout bij temperatuur, zorg ervoor dat al het voedsel dat is opgeslagen op de verkeerde temperatuur verwijderd wordt.',
    enableLockdown: 'Schakel blokkering in',
    enableLockdownConfirm:
      'Weet u zeker dat u de blokkering voor deze locker wilt inschakelen? Klanten kunnen hun bestellingen niet ophalen, zolang dit is ingeschakeld.',
    lockdownMessage:
      'De box werd geblokkeerd omwille van problemen met de temperatuur. Ga na wat de oorzaak is en controleer de kwaliteit van de producten in de box. Klik bij lockers op ‘schakel blokkering uit’ om afhalingen opnieuw toe te laten.',
    hasBeenModified: 'Locker is aangepast',
    inTheVersbox: 'In de versbox',
    lockdownStatusUpdated: 'De vergrendelingsstatus is bijgewerkt',
    notFound: 'Locker niet gevonden',
    openAll: 'Open alle vakken',
    openMainDoor: 'Open hoofddeur',
    settings: 'Locker instellingen',
    viewAllReservations: 'Bekijk alle reservaties',
    viewReservation: 'Bekijk reservatie',
  },
  lockerTemperatureType: {
    NotCooled: 'Niet gekoeld',
    Refrigerated: 'Gekoeld',
    RoomTemperature: 'Kamertemperatuur',
    Heated: 'Verwarmd',
  },
  lockStatusses: {
    closed: 'Gesloten',
    open: 'Open',
    opened: 'Geopend',
  },
  log: 'Logboek',
  logo: 'Logo',
  LogOperationEnum: {
    Created: 'Aangemaakt',
    Deleted: 'Verwijderd',
    Error: 'Fout',
    Opened: 'Geopend',
    Received: 'Ontvangen',
    Sent: 'Verstuurd',
    Updated: 'Aangepast',
  },
  logs: 'Logboek',
  logsPage: {
    notFound: 'Log niet gevonden',
    operationFilter: 'Filter op operatie',
    severityFilter: 'Filter op ernst',
    typeFilter: 'Filter op type',
  },
  mainDoor: 'Hoofddeur',
  message: 'Bericht',
  minimumAmount: 'Minimum aantal',
  name: 'Naam',
  newNotifications: 'Er zijn nieuwe notificaties',
  next: 'Volgende',
  noAutoComplete: 'Vak(ken) vast reserveren',
  noBoxReserved: 'Er is nog geen vakje gereserveerd, reserveer een vakje voor dat de klant dit kan afhalen/bestellen.',
  notification: 'Notificatie',
  notifications: 'Notificaties',
  number: 'Nummer',
  nutritionalInformation: 'Voedingswaarden',
  nutritionalInformationItems: {
    addedSugars: 'Toegevoegde suiker',
    carbohydrate: 'Koolhydraten',
    cholesterol: 'Cholesterol',
    energy: 'Energiewaarde (in kCal)',
    fat: 'Vetten',
    fiber: 'Vezels',
    protein: 'Eiwitten',
    salt: 'Zout',
    saturatedFat: 'Waarvan verzadigd',
    sugars: 'Suiker',
  },
  operation: 'Operatie',
  order: 'Bestelling',
  orderAmount: 'Totale bestellingsbedrag',
  joynPoints: 'Joyn punten',
  orderConfirmationEmailTemplate: 'Bestelbevestiging email',
  orderLines: 'Bestelde artikels',
  orderNumber: 'Bestelnummer',
  orders: 'Bestellingen',
  ordersPage: {
    confirmationSent: 'Order bevestiging verstuurd',
    create: 'Maak bestelling aan',
    notFound: 'Bestelling niet gevonden',
    orderCanceled: 'Order is geannuleerd',
    orderChanged: 'Order succesvol aangepast',
    orderStatus: 'Bestelstatus',
    searchOrder: 'Zoek een bestelling...',
  },
  refund: {
    title: 'Terugbetaling',
    minimumRefundError: 'Moet ten minste 1 cent terugbetalen',
    maximumRefundError: 'Kan niet meer terugbetalen dan het totale bestelbedrag',
    amount: 'Terugbetaalbedrag',
    description: 'Omschrijving terugbetaling',
    descriptionPlaceholder: 'Teruggave van goederen #125',
    confirm: 'Bevestig terugbetaling',
    success: 'Succesvol terugbetaald',
    failed: 'Kon bestelling niet terugbetalen',
  },
  orderStatusEnum: {
    Canceled: 'Geannuleerd',
    PaymentCanceled: 'Betaling geannuleerd',
    Created: 'Aangemaakt',
    Expired: 'Vervallen',
    PaymentExpired: 'Betaling verlopen',
    Paid: 'Betaald',
    Refunded: 'Terugbetaald',
  },
  organisation: 'Organisatie',
  organisationScreen: {
    companyName: 'Bedrijfsnaam',
    couldNotRemove: 'Kon organisatie niet verwijderen',
    create: 'Maak organisatie aan',
    created: 'Organisatie aangemaakt',
    registrationNumber: 'Ondernemingsnummer',
    registrationNumberRequired: 'Ondernemingsnummer is verplicht',
    search: 'Zoek organisatie...',
    selectionHeading: 'Organisaties',
  },
  origin: 'Oorsprong',
  overviewScreen: {
    inLocker: 'Locker',
    inQueue: 'Wachtrij',
    someDevicesAreOffline:
      ' "{devices}" zijn offline, dit kan resulteren in reservaties die niet gesynchroniseerd worden met de box.',
    title: 'Overzicht',
    vendingTag: 'Woyti',
    hiddenTag: 'Verborgen',
    lockersInLockdown:
      'Lockers "{lockers}" zijn in lockdown, dit komt waarschijnlijk door een temperatuurprobleem, ga naar de lockers pagina voor meer details.',
    woytiOrdersToNotify: 'Klanten nog informeren over {amount} nieuwe Woyti-reserveringen',
  },
  overwriteCodeScreen: {
    changedSuccess: 'Overwrite code is succesvol aangepast.',
    confirmRemoval: 'Ben je zeker dat je deze overwrite code wilt wissen?',
    create: 'Maak overwrite code aan',
    created: 'Overwrite code is aangemaakt',
    editTitle: 'Pas overwrite code aan',
    invalidPincode: 'De pincode moet uit 6 cijfers bestaan',
    notFound: 'Overschrijvingscode niet gevonden',
    overviewTitle: 'Overwrite codes',
    removedSuccess: 'Overwrite code {name} is verwijderd',
    search: 'Vind een overwrite code...',
  },
  pageHeader: {
    organisationSelection: 'Organisatie selectie',
    shopSelection: 'Winkel selectie',
  },
  paidAt: 'Betaald op',
  password: 'Wachtwoord',
  passwordValidation: {
    length: 'Minimum 8 karakters lang',
    lowercase: 'Bevat kleine letters',
    numbers: 'Bevat getallen',
    uppercase: 'Bevat hoofdletters',
  },
  phonenumber: 'Telefoon Nummer',
  pickupCode: 'Afhaalcode',
  pickupDate: 'Afhaaldatum',
  pickupTime: 'Afhaalmoment',
  pincode: 'Pincode',
  previous: 'Vorige',
  price: 'Prijs',
  pricePerType: 'Prijs per {type}',
  product: 'Artikel',
  products: 'Producten',
  productsPage: {
    changed: 'Product is succesvol aangepast',
    changeFailed: 'Product kon niet worden aangepast',
    create: 'Maak product aan',
    created: 'Product is aangemaakt',
    creationFailed: 'Product kon niet worden aangemaakt',
    deleteFailed: 'Product kon niet verwijderd worden',
    notFound: 'Product niet gevonden',
    removed: 'Product is met succes verwijderd',
    search: 'Zoek een product...',
    deleteAll: 'Delete all',
    productsDeleted: 'Deleted products',
  },
  provideOwnPincode: 'Niet automatisch een pincode genereren',
  quotaDetails: {
    title: 'Limiet details',
    usageLog: 'Verbruik logs',
  },
  quotas: {
    lastResetAt: 'Laatst gereset op',
    resetsAt: 'Wordt gereset op',
    title: 'Limieten',
    type: 'Limiet Type',
    usage: 'Verbruik',
  },
  rawData: 'Ruwe data',
  refresh: 'Ververs',
  refunded: 'Terugbetaald',
  relatedDocuments: 'Gerelateerde documenten',
  removeAt: 'Word automatisch verwijderd op',
  reservation: 'Reservatie',
  reservationActions: {
    boxesRequired: 'Alle vakken moeten geselecteerd worden',
    cancellationWarning:
      'Weet je zeker dat je deze reservatie wilt annuleren? Dit kan niet ongedaan gemaakt worden. Vergeet niet de box leeg te maken na deze actie.',
    completeReservation: 'Volbreng reservatie',
    completeReservationConfirmation:
      'Ben je zeker dat je de reservatie wilt volbrenge? Hierdoor zullen nieuwe reservatie in de box geplaatst worden.',
    insufficientBoxes: 'Niet voldoende beschikbare vakken',
    orderAmountShouldBeLargerThanZero: 'Bestellingsbedrag moet groter zijn dan nul',
    reserveBox: 'Vak reserveren',
    reserveBoxesForReservation: 'Reserveer vak(ken) voor deze reservatie',
    selectABox: 'Selecteer een vak',
    sendNotification: 'Notificatie verzenden',
    sendNotificationConfirmation: 'Ben je zeker dat je de notificatie wilt versturen?',
    shouldSelectALocker: 'U moet een kluis selecteren',
    showRecentlyOpenedBoxes: 'Toon recent geopende vakken?',
    toCreated: 'Haal reservatie uit locker',
    toCreatedConfirmation:
      'Ben je zeker dat je deze reservatie uit de locker wilt halen? Vergeet niet de box leeg te maken na deze actie.',
  },
  reservationReadyEmailTemplate: 'Reservatie klaar Email Template',
  reservationReadySMSTemplate: 'Reservatie klaar SMS Template',
  reservations: 'Reservaties',
  reservationScreen: {
    confirmUpdateSentCompleted:
      'Ben je zeker dat je deze reservatie wilt aanpassen? Er is al een notificatie verstuurd, pas dus zeker niet de klant of pincode aan om problemen te voorkomen. Dit mag enkel gebruikt worden voor fouten in de contactgegevens op te lossen.',
    create: 'Maak reservatie aan',
    createWoyti: 'Creëer Woyti reservering',
    createReservation: 'Maak reservatie aan',
    editReservation: 'Pas reservatie aan',
    formTabs: {
      contactDetails: 'Contact gegevens',
      extraOptions: 'Extra opties',
      general: 'Algemeen',
      vending: 'Woyti',
    },
    hasAlreadyPaid: 'Heeft al betaald',
    invalidPincode: 'De pincode moet uit 4 cijfers bestaan',
    noNotificationTypesSelected:
      'Doordat er geen notificaties geselecteerd zijn zal de klant geen notificatie ontvangen met de pincode om de bestelling op te halen.',
    noNotificationTypesSelectedTitle: 'Er zijn geen notificatie types geselecteerd',
    notFound: 'Er zijn geen reservaties gevonden',
    notFoundListDescription: 'Geen reservaties gevonden, verander de filters om reservaties te vinden.',
    notificationDispatchFailed: 'Kon notificatie niet versturen.',
    notificationSent: 'Notificatie verstuurd.',
    reservationCreated: 'Reservatie is aangemaakt',
    reservationHasChanged: 'Reservatie is aangepast, om nieuwe waarden te zien klik op ververs',
    reservationInformation: 'Reservatie gegevens',
    reservationModified: 'Reservatie is succesvol aangepast',
    reservationStatusFilter: 'Reservatie status',
    search: 'Zoek een reservatie...',
    sendNotificationAt: 'Zend notificatie op',
    shouldCustomerPay: 'Moet de klant nog betalen?',
    shouldPay: 'Moet nog betalen',
    statusChanged: 'Status is succesvol aangepast.',
    statusChangeFailed: 'Status kon niet aangepast worden',
    viewLocker: 'Bekijk locker',
    woytiNotificationDispatched: 'Woyti-melding verzonden',
    woytiNotificationDispatchingFailed: 'Verzenden van Woyti-melding mislukt',
    dispatchWoytiNotification: 'Woyti-melding verzenden',
  },
  reservationSettingsScreen: {
    title: 'Reservatie Instellingen',
    shouldCustomerPay: 'Moet Klant Betalen',
    selectAvailableBoxByDefault: 'Selecteer standaard beschikbare box',
    changed: 'Reserverings instellingen succesvol bijgewerkt',
  },
  reservationStatusEnum: {
    cancelled: 'Geannuleerd',
    completed: 'Bestelling volbracht',
    created: 'Aangemaakt',
    ordered: 'Besteld',
    reserved: 'Gereserveerd',
    sent: 'Melding verstuurd',
  },
  role: 'Rol',
  save: 'Opslaan',
  select: 'Selecteer',
  selectAnOrganisation: 'Selecteer een organisatie',
  sendFeedback: 'Feedback',
  serverNotifications: {
    noNotifications: 'Momenteel geen nieuwe notificaties',
  },
  'service-location': 'Gateway',
  'service-locations': 'Gateways',
  serviceLocationScreen: {
    lastOfflineAt: 'Laatst Offline',
  },
  serviceLocationsPage: {
    changed: 'Gateway gewijzigd',
    changeFailed: 'Kan gateway niet wijzigen',
    create: 'Maak gateway aan',
    created: 'Gateway succesvol aangemaakt',
    creationFailed: 'Gateway kon niet aangemaakt worden',
    notFound: 'Gateway niet gevonden',
    search: 'Zoek gateway...',
  },
  settings: {
    changed: 'Instellingen zijn aangepast',
    generalSettings: 'Algemene instellingen',
    email: {
      settings: 'Emailinstellingen',
      validation: {
        domainRequiresSMTP: 'U dient een SMTP-server in te geven voor alle domeinnamen behalve %s',
      },
      customSMTPWarning:
        'Let op dat we de e-mailaflevering niet kunnen garanderen wanneer u een eigen SMTP-server gebruikt, zelfs na de initiële validatie. Gelieve u ervan te verzekeren dat de SMTP-server correct is ingesteld om mails te verzenden met de opgegeven instellingen.',
      useCustomSettings: 'Gebruik eigen emailinstellingen',
      fromEmail: "Verzendadres ('From: ')",
      smtpServer: 'SMTP-server',
      smtpPort: 'SMTP-port',
    },
    payment: 'Betalingsinstellingen',
    paymentScreen: {
      connectMollie: 'Connecteer met Mollie',
      connectMollieAgain: 'Connecteer Mollie opnieuw',
      disableTestmode: 'Schakel testmodus uit',
      disableTestmodeConfirm: 'Ben je zeker dat je testmodus wilt uitschakelen?',
      disconnectMollie: 'Mollie loskoppelen',
      disconnectMollieConfirm:
        'Ben je zeker dat je mollie wilt loskoppelen, als je dit doet kan je geen betalingen meer ontvangen.',
      enableTestmode: 'Schakel testmodus in',
      enableTestmodeConfirm:
        'Ben je zeker dat je testmodus wilt inschakelen? Dit zal je klanten toelaten te bestellen zonder te betalen.',
      gotoMollieDashboard: 'Ga naar uw Mollie dashboard',
      mollieLinked: 'Mollie is succesvol gelinkt',
      mollieOrganization: 'Mollie organisatie',
      mollieOrganizationName: 'Gelinkte Mollie organisatie',
      mollieProfile: 'Mollie Profiel',
      stillProcessing: 'Gegevens nog aan het verwerken, bekijk het Mollie dashboard voor meer informatie',
      verificationStatus: 'Verificatie status',
      verified: 'Geverifieerd, kan betalingen ontvangen',
    },
    virtualVending: 'Woyti instellingen',
    virtualVendingScreen: {
      findVirtualVendingAt: 'Je kunt je woyti machine vinden op',
      favoriteCount: 'Woyti aantal favorieten: {count}',
    },
    joyn: {
      title: 'Joyn Instellingen',
      shopReference: 'Joyn Shop Referentie',
    }
  },
  settingsPage: {
    changed: 'Instellingen sucesvol aangepast',
    updateFailed: 'Kon instellingen niet aanpassen',
  },
  severityEnum: {
    danger: 'Gevaar',
    info: 'Info',
    success: 'Geslaagd',
    warning: 'Waarschuwing',
  },
  shop: 'Winkel',
  shopScreen: {
    create: 'Maak winkel aan',
    createdSuccessfully: 'Winkel succesvol aangemaakt',
    heading: 'Winkels',
    hideVirtualVendingInOverview: 'Verberg Woyti in overzicht',
    invalidPickupTime: 'Ongeldige afhaaltijd geselecteerd',
    invalidTimeBeforeDispatch: 'Tijd voor bevestiging is ongeldig',
    organisationSettings: 'Organisatie Instellingen',
    overview: 'Winkels overzicht',
    search: 'Zoek een winkel',
    timeBeforeConfirmationDispatch: 'Tijd voor bevestigingsnotificatie',
  },
  sidebar: {
    hide: 'Verberg zijbalk',
  },
  smsNotification: 'SMS Notificatie',
  state: 'Provincie',
  status: 'Status',
  street: 'Straat',
  subject: 'Onderwerp',
  temperature: 'Temperatuur',
  testDevice: 'Test apparaat',
  time: 'Tijd',
  total: 'Totaal',
  type: 'Type',
  undo: 'Ongedaan maken',
  unitEnum: {
    liter: 'Liter',
    kg: 'Kilogram',
    unit: 'Eenheid',
  },
  unitAmount: {
    liter: 'Centiliter',
    kg: 'Gram',
    unit: 'Eenheid',
  },
  disclaimer: {
    usingAgreeToTerms: 'Door gebruik te maken van deze toepassing gaat u akkoord met onze algemene voorwaarden',
  },
  unitType: 'Eenheid',
  unknown: 'Ongekend',
  user: 'Gebruiker',
  username: 'Gebruikersnaam',
  userDropdown: {
    changePassword: 'Verander wachtwoord',
    logout: 'Log uit',
    preferences: 'Gebruikersvoorkeuren',
  },
  userPreferences: {
    title: 'Gebruikersvoorkeuren',
    updatedSuccess: 'Gebruikersinstellingen succesvol aangepast',
  },
  usersPage: {
    deleteConfirmation: 'Ben je zeker dat je deze gebruiker wilt verwijderen?',
    invite: 'Nodig een gebruiker uit',
    inviteSendingFailed: 'Uitnodiging kon niet verstuurd worden',
    inviteSent: 'Uitnodiging is verstuurd',
    pincodeUpdated: 'PIN Code succesvol aangepast',
    pincodeUpdateFailed: 'Kon PIN Code niet aanpassen',
    sendInvitation: 'Verzend uitnodiging',
    usersTitle: 'Gebruikers',
  },
  validation: {
    addressRequired: 'Adres is verplicht',
    amountOfBoxes: 'Aantal boxen moet groter zijn of gelijk aan 1',
    boxCount: 'Aantal boxen is verplicht',
    contentRequired: 'Inhoud is verplicht',
    descriptionRequired: 'Beschrijving is verplicht',
    emailRequired: 'Email is verplicht',
    firstNameRequired: 'Voornaam is verplicht',
    intervalTooSmall: 'Interval moet groter of gelijk zijn aan 1',
    invalidEmail: 'Email adres is ongeldig',
    invalidName: 'Naam is ongeldig',
    invalidPhonenumber: 'Ongeldig telefoonnummer',
    language: 'Taal is verplicht',
    lastNameRequired: 'Achternaam is verplicht',
    locker: 'Locker is verplicht',
    minimumAmountTooSmall: 'Minimum aantal moet groter of gelijk zijn aan 1',
    nameRequired: 'Naam is verplicht',
    phonenumber: 'Telefoon Nummer is ongeldig',
    pickupInFuture: 'Afhaalmoment moet in de toekomst zijn',
    pickupTimeRequired: 'Ophaaltijd is verplicht',
    priceTooLow: 'Prijs moet ten minste 0 zijn',
    role: 'Rol is verplicht',
    serviceLocation: 'Gateway is vereist',
    tinInvalid: 'BTW nummer is ongeldig',
    tinRequired: 'BTW nummer is verplicht',
    type: 'Type is verplicht',
    vendingProducts: 'Een woyti reservatie moet op zn minst 1 product bevatten',
    fieldIsRequired: 'Dit veld mag niet leeg zijn',
  },
  vatNumber: 'BTW Nummer',
  vatRate: 'BTW Tarief',
  vending: 'woyti',
  vendingPickupTime: 'Tijd tussen bestelling en ophaling',
  vendingProducts: 'Producten in Woyti reservering',
  viewAll: 'Bekijk alles',
  virtualVendingMachine: 'Woyti',
  zipcode: 'Postcode',
  confirm: 'Bevestig',
  webshopUrl: 'Webshop url',
  integrations: {
    title: 'Integraties',
    integration: 'Integratie',
  },
  autoHideAfterXHours: 'Automatisch verbergen na x uur',
  hours: 'uren',
  temperatureLogs: {
    title: 'Temperatuur logs',
  },
  registeredAt: 'Geregistreerd op',
};
